<template>
  <div class="address-page">
    <headerBar :title="i18n.t('address.title')"  :showArrow="true" @back="back"></headerBar>

    <div class="flex-center-end remove-btn">
      <!-- <img :src="removeUrl" alt=""> -->
      <!-- <span>删除</span> -->
    </div>

    <div class="address-form full">
      <div class="form-item">
        <div class="label">{{i18n.t('address.label1')}}</div>
        <div class="input ">
          <input type="text" v-model="form.username" :placeholder="i18n.t('address.placeholder1')">
        </div>
      </div>
      <div class="form-item">
        <div class="label">{{i18n.t('address.label2')}}</div>
        <div class="input ">
          <input type="text" v-model="form.phone" :placeholder="i18n.t('address.placeholder2')">
        </div>
      </div>
      <div class="form-item">
        <div class="label">{{i18n.t('address.label3')}}</div>
        <div class="input ">
          <input type="text" v-model="form.adName" :placeholder="i18n.t('address.placeholder3')">
        </div>
      </div>
      <div class="form-item">
        <div class="label">{{i18n.t('address.label4')}}</div>
        <div class="input ">
          <input type="text" v-model="form.address" :placeholder="i18n.t('address.placeholder4')">
        </div>
      </div>
      <div class="btn">
        <Button @click="submit">{{i18n.t('address.btn')}}</Button>
      </div>
    </div>


  </div>
</template>
<script>
import { Button } from 'vant'
import headerBar from '@/components/header'
export default {
  components: {
    headerBar,
    Button
  },
  data() {
    return {
      removeUrl: require('../../assets/imgs/icon-remove.png'),
      form: {
        number: '',
        address: '',
        password: '',
      },
      userMoney: '0.00',
    }
  },
  mounted() {

  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    setMoney(money) {
      this.form.number =  money
    },
    submit() {

    },
    setAll() {
      this.form.money = this.userMoney
    }
  }
}
</script>